<template>
  <div class="article">
    <div class="actionBar">
      <a-space>
        <a-tree-select
          allowClear
          v-model="listParams.status"
          style="width: 120px"
          :tree-data="check_status"
          placeholder="状态(全部)"
          @change="statusChange"
        />
        <CompanySelect
          @CompanySelect="CompanySelect"
          :serchKeyword="listParams.company_id"
          :selectId="listParams.company_id"
          style="width:200px"
        ></CompanySelect>
        <GroupSelect
          @GroupSelect="GroupSelect"
          :selectId="listParams.group_id"
        ></GroupSelect>
        <a-range-picker
          style="width: 280px"
          show-time
          v-model="time"
          format="YYYY-MM-DD HH:mm:ss"
          valueFormat="YYYY-MM-DD HH:mm:ss"
          :locale="locale"
          @change="timeChange"
          @ok="timeOk"
        >
        </a-range-picker>
        <a-input-search
          v-model="listParams.keyword"
          placeholder="请输入关键词搜索"
          enter-button="查询"
          style="width: 220px"
          allowClear
          @search="onSearch"
        />
        <a-button type="primary" @click="empty"> 清空</a-button>
        <a-button
          icon="download"
          type="primary"
          @click="downloadR"
          v-if="handLists.top.indexOf('export') != -1"
        >
          导出结果</a-button
        >
      </a-space>

      <a-space v-if="handLists.top.indexOf('add') != -1">
        <a-button icon="plus" type="primary" @click="addVideo">
          添加视频
        </a-button>
        <a-button icon="plus" type="primary" @click="batchSaveVideo">
          批量添加
        </a-button>
      </a-space>
    </div>

    <!-- 对话框 -->
    <a-modal v-model="visible" @ok="handleOk" okText="确定" cancelText="取消">
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="评论数量" v-if="commentFlag == 1">
          <a-input placeholder="请输入评论数量" v-model="commentNum" />
        </a-form-item>
        <a-form-item label="评论内容" v-else>
          <a-textarea
            placeholder="请填写评论内容"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            v-model="commentC"
          />
        </a-form-item>
      </a-form>
    </a-modal>

    <a-modal v-model="visible2" :footer="null" @cancel="outVideo">
      <video :src="videoUrl" controls autoplay ></video>
    </a-modal>

    <!-- 对话框 -->

    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="id"
      :pagination="{
        total: count,
        current: listParams.page,
        pageSize: listParams.limit,
        showTotal: (total) => `共 ${total} 条`,
      }"
      @change="pageChange"
    >
      <span slot="titles" slot-scope="text, record">
        <a @click="viewDetail(record.id)">{{ text }}</a>
      </span>
      <span slot="list_order" slot-scope="text, record">
        <a-input-number
          :min="1"
          v-model="record.list_order"
          style="width:80px"
          @focus="saveOldNum(text)"
          @blur="AchangeVal(record.id, record.list_order, 0)"
          v-if="handLists.right.indexOf('setNumber') != -1"
        >
        </a-input-number>
        <span v-else>
          {{ record.list_order }}
        </span>
      </span>
      <span slot="video_url" slot-scope="text">
        <a-icon
          type="video-camera"
          class="videoCamera"
          @click="videoPev(text)"
        />
      </span>

      <span slot="post_hits" slot-scope="text, record">
        <div v-if="handLists.right.indexOf('setNumber') != -1">
          
        <a-input-number
          :min="1"
          v-model="record.post_hits"
          style="width:100px"
          @focus="saveOldNum(text)"
          @blur="AchangeVal(record.id, record.post_hits, 1)"
          
        >
      
        </a-input-number>
        <a-button size="small" type="primary" @click="updateNum(record.id,'video_manage',1)" style="margin-top: 5px;">
            更新</a-button>

        </div>

        <span v-else>
          {{ record.post_hits }}
        </span>
      </span>
      <span slot="post_like" slot-scope="text, record">
        <div v-if="handLists.right.indexOf('setNumber') != -1">
          <a-input-number
          :min="1"
          v-model="record.post_like"
          style="width:100px"
          @focus="saveOldNum(text)"
          @blur="AchangeVal(record.id, record.post_like, 2)"
          
        >
        </a-input-number>
        <a-button size="small" type="primary" @click="updateNum(record.id,'video_manage',3)" style="margin-top: 5px;">
            更新</a-button>
        </div>

        

        <span v-else>
          {{ record.post_like }}
        </span>
      </span>
      <span slot="comment_count" slot-scope="text, record">
        <span v-if="text == 0">
          {{ text }}
        </span>
        <a @click="goComment(record.id)" v-else>{{ text }}</a>
      </span>
      <span slot="post_favorites" slot-scope="text, record">
        <div v-if="handLists.right.indexOf('setNumber') != -1">
          <a-input-number
          :min="1"
          v-model="record.post_favorites"
          style="width:100px"
          @focus="saveOldNum(text)"
          @blur="AchangeVal(record.id, record.post_favorites, 3)"
          
        >
        </a-input-number>
        <a-button size="small" type="primary" @click="updateNum(record.id,'video_manage',2)" style="margin-top: 5px;">
            更新</a-button>

        </div>

   
      </span>
      <span slot="post_status" slot-scope="text, record">
        <span v-if="handLists.right.indexOf('setStatus') != -1">
          <a-popover placement="topRight">
            <template slot="content">
              {{ record.is_top == 1 ? "点击取消置顶" : "点击置顶" }}
            </template>
            <a-tag
              :color="record.is_top == 1 ? 'green' : '#999'"
              @click="AsetStatus(record.id, record.is_top == 1 ? 2 : 1)"
            >
              {{ record.is_top == 1 ? "已置顶" : "未置顶" }}
            </a-tag>
          </a-popover>
          <a-popover placement="topLeft">
            <template slot="content">
              {{ record.recommended == 1 ? "点击取消推荐" : "点击推荐" }}
            </template>
            <a-tag
              :color="record.recommended == 1 ? 'green' : '#999'"
              @click="AsetStatus(record.id, record.recommended == 1 ? 4 : 3)"
            >
              {{ record.recommended == 1 ? "已推荐" : "未推荐" }}
            </a-tag>
          </a-popover>
        </span>
        <span v-else>
          <a-tag :color="record.is_top == 1 ? 'green' : '#999'">
            {{ record.is_top == 1 ? "已置顶" : "未置顶" }}
          </a-tag>
          <a-tag :color="record.recommended == 1 ? 'green' : '#999'">
            {{ record.recommended == 1 ? "已推荐" : "未推荐" }}
          </a-tag>
        </span>
      </span>

      <span slot="action" slot-scope="text, record">
        <span v-if="handLists.right.indexOf('setComment') != -1">
          <FontIcon
            @click.native="showModal(record.id, 1)"
            title="添加评论"
            type="icon-add-fill"
            size="22"
          ></FontIcon>
          <a-divider type="vertical" />
          <FontIcon
            @click.native="showModal(record.id, 2)"
            title="定制评论"
            type="icon-dingzhi"
            size="22"
          ></FontIcon>
          <a-divider type="vertical" />
        </span>

        <span v-if="handLists.right.indexOf('edit') != -1">
          <FontIcon
            @click.native="editMenu(record)"
            title="修改"
            type="icon-xiugai"
            size="22"
          ></FontIcon>
          <a-divider type="vertical" />
        </span>

        <span v-if="handLists.right.indexOf('del') != -1">
          <a-popconfirm
            title="确定删除？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="confirm(record)"
          >
            <FontIcon title="删除" type="icon-lajitong" size="22"></FontIcon>
          </a-popconfirm>

          <a-divider type="vertical" />
        </span>

        <FontIcon
          @click.native="copyLink(record.web_url)"
          title="点击复制链接"
          type="icon-lianjiefenxiang"
          size="22"
        ></FontIcon>
      </span>
    </a-table>
  </div>
</template>

<script>
import {
  videoIndex,
  videoDelete,
  videoSetStatus,
  videoChangeVal,
  commentBrushComment,
  commentCustomComment,
  ajaxUpdateNum
} from "@/apis/index";
import { videoExportResult } from "@/apis/down";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import moment from "moment";
const columns = [
  {
    title: "排序",
    dataIndex: "list_order",
    scopedSlots: { customRender: "list_order" },
  },
  {
    title: "ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
  },
  {
    title: "视频标题",
    dataIndex: "title",
    scopedSlots: { customRender: "titles" },
  },
  {
    title: "发布者",
    dataIndex: "users",
    scopedSlots: { customRender: "users" },
    customRender: (text) => {
      return text.user_nickname;
    },
    width: 120,
    align: "center",
  },
  {
    align: "center",
    title: "视频预览",
    dataIndex: "video_url",
    scopedSlots: { customRender: "video_url" },
    width: 90,
  },
  {
    title: "评论量",
    dataIndex: "comment_count",
    scopedSlots: { customRender: "comment_count" },
    width: 100,
    align: "center",
  },
  {
    title: "查看量",
    dataIndex: "post_hits",
    scopedSlots: { customRender: "post_hits" },
    width: 100,
  },
  {
    title: "点赞量",
    dataIndex: "post_like",
    scopedSlots: { customRender: "post_like" },
    width: 100,
  },
  {
    title: "收藏量",
    dataIndex: "post_favorites",
    scopedSlots: { customRender: "post_favorites" },
    width: 100,
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
    scopedSlots: { customRender: "create_time" },
    customRender: (text) => {
      if (text > 0) {
        return moment(Number(text + "000")).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return "";
      }
    },
    width: 120,
  },
  {
    title: "设置状态",
    dataIndex: "post_status",
    scopedSlots: { customRender: "post_status" },
    width: 160,
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: 210,
  },
];
const check_status = [
  {
    title: "置顶",
    value: 1,
  },
  {
    title: "未置顶",
    value: 2,
  },
  {
    title: "已推荐",
    value: 3,
  },
  {
    title: "未推荐",
    value: 4,
  },
];
export default {
  data() {
    return {
      companyList: [],
      check_status,
      locale,
      columns,
      data: [],
      listParams: {
        page: 1,
        limit: 10,
        keyword: "",
        status: undefined,
        group_id: undefined,
        start_time: "",
        end_time: "",
        company_id: undefined,
      },
      count: 0,
      time: [],
      visible: false,
      commentFlag: 1,
      commentNum: "",
      commentC: "",
      articleId: "",
      visible2: false,
      videoUrl: "",
      oldNum: "",
      handLists: this.$store.state.menuHandleList,
    };
  },
  computed: {
    handList() {
      return this.$store.state.menuHandleList;
    },
  },
  watch: {
    handList(val) {
      this.handLists.top = val.top ? val.top : [];
      this.handLists.right = val.right ? val.right : [];
    },
  },

  created() {
    let condition = this.$store.state.condition;
    if (condition.path == this.$route.path) {
      this.listParams = condition.listParams;
      this.time = [
        condition.listParams.start_time,
        condition.listParams.end_time,
      ];
    }
    this.getVideoIndex();
  },
  methods: {
    updateNum(id, table_name, change_type) {
      let params = {
        id,
        table_name,
        change_type,
      }
      let type;
      switch (change_type) {
        case 1:
          type = 'post_hits'
          break;
        case 2:
          type = 'post_favorites'
          break;
        default:
          type = 'post_like'
          break;
      }
      ajaxUpdateNum(params).then(res => {
        this.data.find(item => {
          if (item.id === id) {
            item[type] = res.data.num
          }
        })
      })
    },
    empty() {
      (this.listParams = {
        page: 1,
        limit: 10,
        keyword: "",
        status: undefined,
        group_id: undefined,
        start_time: "",
        end_time: "",
        company_id: undefined,
      }),
        (this.time = []);
      this.getVideoIndex();
      this.$store.commit("setCondition", {
        path: "",
        listParams: {},
      });
    },

    CompanySelect(val) {
      this.listParams.company_id = val;
      this.getVideoIndex();
    },
    copyLink(data) {
      let OrderNumber = data;
      let newInput = document.createElement("input");
      newInput.value = OrderNumber;
      document.body.appendChild(newInput);
      newInput.select();
      document.execCommand("Copy");
      newInput.remove();
      this.$message.success("复制成功");
    },
    timeChange(a) {
      if (a.length > 0) {
        this.listParams.start_time = a[0];
        this.listParams.end_time = a[1];
      } else {
        this.listParams.start_time = "";
        this.listParams.end_time = "";
        this.getVideoIndex();
      }
    },
    timeOk() {
      this.getVideoIndex();
    },
    saveOldNum(val) {
      this.oldNum = val;
    },
    AchangeVal(id, num, type) {
      if (this.oldNum == num) {
        return false;
      } else {
        videoChangeVal({
          id,
          change_value: num,
          change_type: type,
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success("修改成功");
            this.getVideoIndex();
          }
        });
      }
    },
    GroupSelect(val) {
      this.listParams.group_id = val;
      this.getVideoIndex();
    },
    goComment(id) {
      this.$router.push({
        path: "/datamaintenances/comment",
        query: { object_id: id, table_name: "video_manage" },
      });
    },
    viewDetail(id) {
      this.$store.commit("setCondition", {
        path: this.$route.path,
        listParams: this.listParams,
      });
      this.$router.push({ path: "/contents/VideoDetail", query: { id } });
    },
    editMenu(record) {
      this.$store.commit("setCondition", {
        path: this.$route.path,
        listParams: this.listParams,
      });
      this.$router.push({
        path: "/contents/AddEditVideo",
        query: {
          id: record.id,
        },
      });
    },
    addVideo() {
      this.$store.commit("setCondition", {
        path: this.$route.path,
        listParams: this.listParams,
      });
      this.$router.push({ path: "/contents/AddEditVideo" });
    },
    batchSaveVideo() {
      this.$store.commit("setCondition", {
        path: this.$route.path,
        listParams: this.listParams,
      });
      this.$router.push({ path: "/contents/BatchSaveVideo" });
    },

    outVideo() {
      this.videoUrl = "";
    },
    videoPev(url) {
      this.visible2 = true;
      this.videoUrl = url;
    },
    showModal(id, val) {
      this.articleId = id;
      this.commentNum = "";
      this.commentC = "";
      this.visible = true;
      this.commentFlag = val;
    },
    handleOk() {
      if (this.commentFlag == 1) {
        commentBrushComment({
          id: this.articleId,
          table_name: "video_manage",
          num: this.commentNum,
        }).then((res) => {
          if (res.code == 1) {
            this.visible = false;
            this.$message.success("评论数量添加成功");
            this.getVideoIndex();
          }
        });
      } else {
        commentCustomComment({
          id: this.articleId,
          table_name: "video_manage",
          content: this.commentC,
        }).then((res) => {
          if (res.code == 1) {
            this.visible = false;
            this.$message.success("定制评论添加成功");
            this.getVideoIndex();
          }
        });
      }
    },

    AsetStatus(id, val) {
      videoSetStatus({
        ids: id,
        status: val,
      }).then((res) => {
        if (res.code == 1) {
          this.getVideoIndex();

          this.$message.success("状态修改成功");
        }
      });
    },
    downloadR() {
      videoExportResult(this.listParams).then((res) => {
        if (res.status == 200) {
          let blob = new Blob([res.data], {
            type: `application/csv`, //word文档为msword,pdf文档为pdf
          });
          let objectUrl = URL.createObjectURL(blob);
          let link = document.createElement("a");
          let fname = `视频列表.csv`; //下载文件的名字
          link.href = objectUrl;
          link.setAttribute("download", fname);
          document.body.appendChild(link);
          link.click();
        }
      });
    },
    confirm(rec) {
      videoDelete({ id: rec.id }).then((res) => {
        if (res.code == 1) {
          this.$message.success("删除成功");
          this.getVideoIndex();
        }
      });
    },
    pageChange(a) {
      this.listParams.page = a.current;
      this.getVideoIndex();
    },
    getVideoIndex() {
      videoIndex(this.listParams).then((res) => {
        if (res.data.list.length == 0 && this.listParams.page > 1) {
          this.listParams.page--;
          this.getVideoIndex();
        } else {
          this.data = res.data.list;
          this.count = res.data.count;
        }
      });
    },
    onSearch() {
      this.listParams.page = 1;
      this.getVideoIndex();
    },
    statusChange() {
      this.getVideoIndex();
    },
  },
};
</script>

<style lang="less" scoped>
.article {
  .actionBar {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
  }

  .videoCamera {
    font-size: 20px;
    margin: 5px;
  }
  .videoCamera:hover {
    font-size: 30px;
    transition: all 0.3s;
    color: rgb(88, 116, 199);
  }
}
</style>
