/**
 * 通用的请求axios  --- 工具函数层
 */

// 引入库
import axios from "axios";
// 引入本地存储工具函数
import local from "@/utils/local";
// 按需引入组件
// 设置默认请求的接口地址
let baseURL = "";
// let baseURL = "";
// 创建实例
let instance = axios.create({
  baseURL,
});
const token = local.get("adminToken");
instance.defaults.headers.common["Token"] = token;
instance.defaults.headers.common["XX-Device-Type"] = 'web'; 
// 请求拦截器
// 导出两个方法
export default {
  get(url, params = {}) {
    return new Promise((resolve, reject) => {
      instance
        .get(url, {params,responseType: 'blob'})
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};