import req from '@/utils/download'
import aaa from '@/config/config'
let api=aaa.aps
export const commentExportResult= params => req.get(`${api}/manage/v1/comment/exportResult`, params)
export const articleExportResult= params => req.get(`${api}/manage/v1/article/exportResult`, params)
export const casesExportResult= params => req.get(`${api}/manage/v1/cases/exportResult`, params)
export const videoExportResult= params => req.get(`${api}/manage/v1/video/exportResult`, params)
export const userExportResult= params => req.get(`${api}/manage/v1/user/exportResult`, params)
export const signExportResult= params => req.get(`${api}/manage/v1/sign/exportResult`, params)


export const answerExportResult= params => req.get(`${api}/manage/v1/answer/exportResult`, params)
// export const signExportResult= params => req.get(`${api}/v3/sign/exportResult`, params)
export const planExportDetail= params => req.get(`${api}/v3/plan/exportDetail`, params)
//   资料审核导出
export const materialExportResult= params => req.get(`${api}/manage/v1/material/exportResult`, params)




